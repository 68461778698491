<template>
    <div :class="'m-p profile-container ' + ( this.$store.state.profileSideBar ? 'open' : '' )">
       <TicketDetails />
    </div>
 </template>
 <script>
 import { defineAsyncComponent } from 'vue'
 export default {
 name: 'Ticket Details',
 components: {
    TicketDetails: defineAsyncComponent( () => import('@/components/Profile/Support/TicketDetails.vue') ),
 },
 }
 </script>